<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ localId ? "แก้ไขรายงาน" : "สร้างรายงาน" }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon :ios="closeOutline" :md="closeSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-item lines="none">
        <ion-label>ชื่อรายงาน</ion-label>
        <ion-input
          v-model.trim="localName"
          align="end"
          placeholder="กรุณาระบุชื่อ"
        ></ion-input>
      </ion-item>

      <!-- เลือกว่าจะเอา categories ไหนมาแสดงบ้าง -->
      <ion-list>
        <ion-item-divider>
          <ion-label>หมวดหมู่ในรายงาน</ion-label>
          <ion-button slot="end" color="secondary" @click="openAccountPicker">
            <ion-label>เลือก</ion-label>
          </ion-button>
        </ion-item-divider>
      </ion-list>

      <ion-item
        v-for="account in selectedAccountsForDisplay"
        :key="account.account_id"
      >
        <ion-label>{{ account.label }}</ion-label>
        <ion-button
          slot="start"
          class="btnColorPicker"
          :style="{
            '--background': localAccountColors[account.account_id],
            '--background-activated': localAccountColors[account.account_id],
          }"
          @click="pickColor(account.account_id)"
        ></ion-button>
        <ion-button
          slot="end"
          color="light"
          @click="removeSelectedAccount(account.account_id)"
        >
          <ion-icon :ios="removeOutline" :md="removeSharp"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item v-if="selectedAccountsForDisplay.length === 0">
        <ion-label color="medium" style="font-size: 80%"
          >กดปุ่ม "เลือก" เพื่อระบุว่าจะแสดงหมวดหมู่ใดในรายงานบ้าง</ion-label
        >
      </ion-item>

      <div
        class="colorPickerBackdrop"
        v-if="picking_color_acc_id"
        @click="onClickColorPickerBackdrop"
        ref="refBackdrop"
      >
        <div class="middleLayer">
          <color-picker @input="changeColor" />
        </div>
      </div>
    </ion-content>

    <ion-footer>
      <!-- ซ่อนปุ่มบันทึก เวลาเปิด color picker -->
      <div v-if="!picking_color_acc_id" class="ion-padding">
        <ion-button
          expand="block"
          :disabled="isSubmitButtonDisabled"
          @click="save"
        >
          {{ submitButtonLabels }}
        </ion-button>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script typeof="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFooter,
  modalController,
} from "@ionic/vue";
import {
  closeOutline,
  closeSharp,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import { closeModal, openMultipleAccountPicker } from "../../../helper.js";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ColorPicker from "../../components/ColorPicker.vue";
import { colors } from "../../components/colors.js";
export default {
  name: "ModalCustomReportEditor",
  components: {
    // ionic components
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonFooter,
    // color picker
    ColorPicker,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    selectedAccounts: {
      type: Array,
      default: () => [],
    },
    accountColors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const localId = ref(props.id);
    const localName = ref(props.name);
    const selectedAccounts = ref(props.selectedAccounts);
    const accountColors = ref(props.accountColors);
    const color = ref(null);
    const picking_color_acc_id = ref(null);

    const basicColors = colors;

    const hashedCategories = store.getters["account/hashedCategories"];

    const selectedAccountsForDisplay = computed(() => {
      const items = [];
      for (const id of selectedAccounts.value) {
        items.push({
          account_id: id,
          label:
            hashedCategories[id].parentString +
            hashedCategories[id].account_name,
        });
      }
      return items;
    });

    const isSubmitButtonDisabled = computed(() => {
      return !(localName.value && selectedAccounts.value.length > 0);
    });
    const submitButtonLabels = computed(() => {
      if (localName.value && selectedAccounts.value.length) {
        return "Save";
      } else if (!localName.value && !selectedAccounts.value.length) {
        return "โปรดระบุข้อมูล";
      } else if (!localName.value) {
        return "โปรดระบุชื่อรายงาน";
      } else if (!selectedAccounts.value.length) {
        return "โปรดเลือกหมวดหมู่";
      } else {
        return "n/a";
      }
    });

    const openingAccountPicker = ref(false);
    const openAccountPicker = async () => {
      if (openingAccountPicker.value) return;

      openingAccountPicker.value = true;
      return await openMultipleAccountPicker({
        accounts: store.getters["account/currentSaveCategories"],
        selectedItems: [...selectedAccounts.value],
        categoryMode: true,
        maxItems: 100,
        clearFilterAfterSelected: false,
        onReceivedData: (data) => {
          let i = 0;
          for (const acc_id of data) {
            if (!accountColors.value[acc_id]) {
              accountColors.value[acc_id] =
                basicColors[i % basicColors.length];
              i += 12;
            }
          }
          selectedAccounts.value = [...data];
        },
      }).then(() => {
        return new Promise((resolve) => {
          openingAccountPicker.value = false;
          resolve();
        });
      });
    };

    const removeSelectedAccount = (account_id) => {
      const index = selectedAccounts.value.indexOf(account_id);
      console.log(index);
      if (index >= 0) {
        selectedAccounts.value.splice(index, 1);
      }
    };

    const pickColor = (acc_id) => {
      if (picking_color_acc_id.value !== acc_id) {
        color.value = accountColors.value[acc_id];
        console.log(color.value);
        picking_color_acc_id.value = acc_id;
      } else {
        // close color picker
        picking_color_acc_id.value = null;
      }
    };
    const changeColor = (data) => {
      accountColors.value[picking_color_acc_id.value] = color.value = data;
      picking_color_acc_id.value = null;
    };

    const refBackdrop = ref(null);
    const onClickColorPickerBackdrop = (event) => {
      if (refBackdrop.value === event.target) {
        picking_color_acc_id.value = null;
      }
    };

    const save = async () => {
      const payload = {
        id: localId.value,
        name: localName.value,
        accounts: [],
      };
      for (const account_id of selectedAccounts.value) {
        payload.accounts.push({
          account_id,
          color: accountColors.value[account_id],
        });
      }
      return await modalController.dismiss(payload);
    };

    return {
      // ref
      refBackdrop,
      // data
      localId,
      localName,
      localAccountColors: accountColors,
      color,
      picking_color_acc_id,
      // computed
      selectedAccountsForDisplay,
      isSubmitButtonDisabled,
      submitButtonLabels,
      // methods
      close: closeModal,
      save,
      removeSelectedAccount,
      openAccountPicker,
      changeColor,
      pickColor,
      onClickColorPickerBackdrop,
      // icons
      closeOutline,
      closeSharp,
      removeOutline: trashOutline,
      removeSharp: trashSharp,
    };
  },
};
</script>

<style scoped>
.btnColorPicker {
  width: 32px;
  height: 32px;
}
.middleLayer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.colorPickerBackdrop {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
