<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>รายงานแบบกำหนดเอง</ion-title>
        <ion-buttons slot="start">
          <ion-button :router-link="{ name: 'settings' }" router-direction="back">
            <ion-icon :ios="backOutline" :md="backSharp"></ion-icon>
            <ion-label>Back</ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <ion-list lines="none">
        <ion-list-header>
          <p>
            <ion-icon :ios="reportOutline" :md="reportSharp"></ion-icon>
            รายงานแบบกำหนดเอง
          </p>
        </ion-list-header>
        <ion-item>
          <ion-text class="small">
            คุณสามารถเลือกได้เอง ว่าจะแสดงหมวดหมู่ใดบ้าง และแสดงเป็นสีอะไร
            เพื่อนำมาเปรียบเทียบกันให้เห็นภาพรวมในมุมมองที่คุณต้องการ
          </ion-text>
        </ion-item>
      </ion-list>

      <div class="ion-padding">
        <ion-button @click="openCreateModal">เพิ่มรายงานแบบกำหนดเอง</ion-button>
      </div>

      <!-- แสดงรายการ custom reports ที่มีอยู่ -->
      <ion-item v-if="Object.keys(currentSavePrefCustomReports).length" lines="none">
        <ion-text>รายงานแบบกำหนดเอง ที่สร้างไว้แล้ว</ion-text>
      </ion-item>
      <ion-item v-for="cr in currentSavePrefCustomReports" :key="cr.id">
        <ion-label>{{ cr.name }}</ion-label>
        <ion-buttons slot="end">
          <ion-button @click="viewReport(cr.id)">ดูรายงาน</ion-button>
          <ion-button @click="editReport(cr.id)">แก้ไข</ion-button>
          <ion-button @click="deleteReport(cr.id)">ลบ</ion-button>
        </ion-buttons>
      </ion-item>

    </ion-content>
  </ion-page>
</template>

<script>
import { chevronBackOutline, chevronBackSharp, pieChartOutline, pieChartSharp } from "ionicons/icons";
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonText,
  IonTitle, IonToolbar, modalController
} from "@ionic/vue";
import ModalCustomReportEditor from "./ModalCustomReportEditor.vue";
import { computed, inject, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "CustomReports",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
  },
  setup() {
    const store = useStore();
    const openingModal = ref(false);

    const openCustomReportEditorModal = async (componentProps) => {
      if (openingModal.value)
        return;

      openingModal.value = true;
      const modal = await modalController.create({
        component: ModalCustomReportEditor,
        componentProps,
        swipeToClose: false,
      });
      modal.onWillDismiss().then(async (receiving) => {
        if (receiving.data) {
          if (receiving.data.id) {
            await store.dispatch("save/updateCustomReport", {
              custom_report_id: receiving.data.id,
              save_id: store.state.save.current_save_id,
              payload: receiving.data,
            });
          } else {
            await store.dispatch("save/createCustomReport", {
              save_id: store.state.save.current_save_id,
              payload: receiving.data,
            });
          }
        }
      });
      return modal.present().then(() => {
        return new Promise((resolve) => {
          openingModal.value = false;
          resolve();
        });
      });
    };

    const openCreateModal = async () => {
      return await openCustomReportEditorModal({});
    };

    const ionRouter = inject("navManager");
    const viewReport = (cr_id) => {
      ionRouter.navigate({
        routerDirection: "back",
        routerLink: { name: "report", params: { chart: cr_id } },
      });
    };
    const editReport = async (cr_id) => {
      const custom_report = { ...store.getters["save/currentSavePrefCustomReports"][cr_id] };
      if (!custom_report)
        return;

      const componentProps = {
        id: cr_id,
        name: custom_report.name,
        selectedAccounts: [],
        accountColors: {},
      };
      for (const item of custom_report.items) {
        componentProps.selectedAccounts.push(item.i);
        componentProps.accountColors[item.i] = item.c;
      }

      return await openCustomReportEditorModal(componentProps);
    };
    const deleteReport = async (cr_id) => {
      const alert = await alertController.create({
        header: "ยืนยันลบรายงาน",
        buttons: [
          {
            text: "ยกเลิก",
            role: "cancel",
          },
          {
            text: "ยืนยันลบ",
            handler: async () => {
              await store.dispatch("save/deleteCustomReport", {
                save_id: store.state.save.current_save_id,
                custom_report_id: cr_id,
              });
            },
          },
        ],
      });
      return alert.present();
    };

    const currentSavePrefCustomReports = computed(() => store.getters["save/currentSavePrefCustomReports"]);

    return {
      // methods
      openCreateModal,
      // Vuex Getters
      currentSavePrefCustomReports,
      // icons
      backOutline: chevronBackOutline,
      backSharp: chevronBackSharp,
      reportOutline: pieChartOutline,
      reportSharp: pieChartSharp,
      // methods
      viewReport,
      editReport,
      deleteReport,
    };
  },
};
</script>

<style scoped></style>
