<template>
  <ul class="colors">
    <li v-for="color in colors" :key="color" class="item" @click="click(color)">
      <div class="alpha"></div>
      <div class="color" :style="{ backgroundColor: color }"></div>
    </li>
  </ul>
</template>

<script>
import { colors } from "./colors.js";

export default {
  name: "ColorPicker",
  emits: ["input"],
  setup(props, { emit }) {
    const click = (color) => {
      emit("input", color.toUpperCase());
    };

    return {
      colors,
      click,
    };
  },
};
</script>

<style scoped>
.colors {
  padding: 2px;
  margin: 0;
  width: 186px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px;
}
.colors .item {
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 3px;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
}
.colors .item .alpha {
  height: 100%;
  border-radius: 4px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAACdJREFUKFNjPHP19n8GJGCspYLMZWCkg4L///+juOHstTuobqC9AgCYGinl3vgvfQAAAABJRU5ErkJggg==");
}
.colors .item .color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
</style>
